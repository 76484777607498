import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextareaAutosize } from '@material-ui/core';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import compact from 'lodash.compact';
import { Ring } from '@joshk/react-spinners-css.ring';
import BaseRequest from '../../helpers/BaseRequest';
import { appendLeadingZeroes } from '../../times/append-leading-zero';
import { Button } from '../../components/buttons/button';
import { ConfirmationModal } from '../../components/confirmation-modal';
import styles from './courses-without-comments.module.css';

type CoursesWithoutCommentsProps = {} & React.HTMLAttributes<HTMLDivElement>;

interface RowData {
  id: string;
  interv_fullname: string;
  interv_activite: string;
  student_fullname: string;
  date: string;
  item: any;
}

const columns: GridColDef[] = [
  { field: 'interv_fullname', headerName: 'שם מטפל', flex: 1 },
  { field: 'interv_activite', headerName: 'תפקיד', flex: 1 },
  { field: 'student_fullname', headerName: 'שם ילד', flex: 1 },
  { field: 'date', headerName: 'תאריך', flex: 1 },
];

const createData = (item: any): RowData => {
  const date_cours_format = new Date(item.date);
  const date_cours =
    appendLeadingZeroes(date_cours_format.getDate()) +
    '-' +
    appendLeadingZeroes(date_cours_format.getMonth() + 1) +
    '-' +
    date_cours_format.getFullYear();
  return {
    id: item.id_cours,
    interv_fullname: item.interv_fullname,
    interv_activite: item.interv_activite,
    student_fullname: item.student_fullname,
    date: date_cours,
    item,
  };
};

export function CoursesWithoutComments({ className, ...rest }: CoursesWithoutCommentsProps) {
  const [rows, setRows] = useState<RowData[]>([]);
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [commentToAdd, setCommentToAdd] = useState('');
  const [customCommentToAdd, setCustomCommentToAdd] = useState('');
  const [showCustomCommentInput, setShowCustomCommentInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await BaseRequest('getCoursesWithoutComment')
      .then((res) => {
        if (res.success === 1) {
          let rowData: RowData[] = [];
          Object.keys(res.data).forEach((key) => {
            rowData.push(createData(res.data[key]));
          });
          setRows(rowData);
        }
        setGetLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setGetLoading(false);
      });
  };

  const onRowsSelectionHandler = (ids: string[]) => {
    const selectedRowsData = compact(ids.map((id) => rows.find((row) => row.id === id)));
    setSelectedRows(selectedRowsData);
  };

  const handleSubmitComments = async () => {
    const formData: FormData = new FormData();
    formData.append('rows', JSON.stringify(selectedRows));
    formData.append('commentToAdd', showCustomCommentInput ? customCommentToAdd : commentToAdd);

    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    setLoading(true);
    await BaseRequest('addCommentToMultipleCourses', formData, headersOptions)
      .then((result) => {
        console.log('result', result);
      })
      .catch((error) => {})
      .finally(async () => {
        setLoading(false);
        setOpenCommentModal(false);
        await getData();
      });
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentToAdd(event.target.value);
    if (event.target.value === 'אחר') {
      setShowCustomCommentInput(true);
    } else {
      setShowCustomCommentInput(false);
    }
  };

  return (
    <Grid direction="column" container justifyContent="flex-start">
      <Grid direction="row" item xs={12} justifyContent="flex-end" container>
        <Grid item xs={3} className={styles.titleHolder}>
          <p className={styles.tableTitle}>טיפוליים שעברו ללא סיכום</p>
        </Grid>
      </Grid>
      <Grid item xs={12} justifyContent="flex-end" container style={{ marginBottom: 8 }}>
        <Button disabled={selectedRows.length === 0} onClick={() => setOpenCommentModal(true)}>
          הוספת הערה
        </Button>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.dataGridHolder}>
          {getLoading ? (
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Ring color="#14738c" />
            </div>
          ) : (
            <DataGrid
              style={{ direction: 'rtl' }}
              rows={rows}
              columns={columns}
              pageSize={10}
              checkboxSelection
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids as string[])}
              localeText={{
                columnMenuHideColumn: 'הסתר עמודה',
                columnMenuShowColumns: 'הצג עמודות',
                columnMenuFilter: 'פילטר',
                MuiTablePagination: {
                  labelDisplayedRows(paginationInfo) {
                    return `${paginationInfo.from}-${paginationInfo.to} מתוך ${paginationInfo.count}`;
                  },
                },
                footerRowSelected(count) {
                  return `${count} שורות שנבחרו`;
                },
              }}
              rowCount={rows.length}
            />
          )}
        </div>
      </Grid>
      <ConfirmationModal
        open={openCommentModal}
        onClose={() => setOpenCommentModal(false)}
        onSubmit={handleSubmitComments}
        title="בחר הערה לטיפולים שנבחרו"
        submitText="הוסף הערה"
        disabledSubmit={showCustomCommentInput ? !customCommentToAdd : !commentToAdd}
        cancelText="ביטול"
        text={
          <FormControl component="fieldset" dir="rtl" style={{ display: 'flex', textAlign: 'right' }}>
            <FormLabel component="legend">הערה</FormLabel>
            <RadioGroup aria-label="comment" name="comment" value={commentToAdd} onChange={handleCommentChange}>
              <FormControlLabel value="אין צורך בתגובה" control={<Radio />} label="אין צורך בתגובה" />
              <FormControlLabel value="אינטק" control={<Radio />} label="אינטק" />
              <FormControlLabel value="אינטק רגשי" control={<Radio />} label="אינטק רגשי" />
              <FormControlLabel value="אחר" control={<Radio />} label="אחר" />
            </RadioGroup>
            {showCustomCommentInput && (
              <TextareaAutosize
                minRows={3}
                placeholder="אחר"
                value={customCommentToAdd}
                onChange={(event) => setCustomCommentToAdd(event.target.value)}
              />
            )}
          </FormControl>
        }
        loading={loading}
      />
    </Grid>
  );
}
